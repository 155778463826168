<template>
  <div>
    <pl-table
      use-virtual
      class="tb-edit"
      :data="tableData"
      border
      stripe
      height="430"
      :cell-style="{padding:0,height:'28px'}"
      :header-cell-style="{padding:0,height:'30px'}"
      :row-height="28"
    >
      <pl-table-column prop="生产日期" label="生产日期" width="120">
        <template slot-scope="scope">
          {{ GetDate(scope.row.生产日期) }}
        </template>
      </pl-table-column>
      <pl-table-column prop="机台" label="机台" width="120"></pl-table-column>
      <pl-table-column prop="结构" label="结构" width="120"></pl-table-column>
      <pl-table-column prop="RUN_ID" label="RUN ID" width="120"></pl-table-column>
      <pl-table-column prop="Wafer_ID" label="Wafer ID" width="120"></pl-table-column>
      <pl-table-column prop="激光编码" label="激光编码" width="120"></pl-table-column>
      <pl-table-column prop="圈" label="圈" width="120"></pl-table-column>
      <pl-table-column prop="衬底尺寸" label="衬底尺寸" width="120"></pl-table-column>
      <pl-table-column prop="WLP" label="WLP" width="120"></pl-table-column>
      <pl-table-column prop="STD" label="STD" width="120"></pl-table-column>
      <pl-table-column prop="Dom_Lambda" label="Dom Lambda" width="120"></pl-table-column>
      <pl-table-column prop="lnt" label="lnt" width="120"></pl-table-column>
      <pl-table-column prop="FWHM" label="FWHM" width="120"></pl-table-column>
      <pl-table-column prop="表面等级" label="表面等级" width="120"></pl-table-column>
      <pl-table-column prop="目检备注" label="目检备注" width="120"></pl-table-column>
      <pl-table-column prop="衬底规格" label="衬底规格" width="120"></pl-table-column>
      <pl-table-column prop="报废判定" label="报废判定" width="120"></pl-table-column>
      <pl-table-column prop="生产类型" label="生产类型" width="120"></pl-table-column>
      <pl-table-column prop="入库日期" label="入库日期" width="120">
        <template slot-scope="scope">
          {{ GetDate(scope.row.入库日期) }}
        </template>
      </pl-table-column>
      <pl-table-column prop="库状态" label="库状态" width="120"></pl-table-column>
      <pl-table-column prop="库存位置" label="库存位置" width="120"></pl-table-column>
      <pl-table-column prop="推荐版型" label="推荐版型" width="120"></pl-table-column>
      <pl-table-column prop="波段" label="波段" width="120"></pl-table-column>
      <pl-table-column prop="综合等级" label="综合等级" width="120"></pl-table-column>
      <pl-table-column prop="版型" label="版型" width="120"></pl-table-column>
      <pl-table-column prop="类型" label="类型" width="120"></pl-table-column>
      <pl-table-column prop="挑片日期" label="挑片日期" width="120">
        <template slot-scope="scope">
          {{ GetDate(scope.row.挑片日期) }}
        </template>
      </pl-table-column>
      <pl-table-column prop="出库日期" label="出库日期" width="120">
        <template slot-scope="scope">
          {{ GetDate(scope.row.出库日期) }}
        </template>
      </pl-table-column>
      <pl-table-column prop="出库流向" label="出库流向" width="120"></pl-table-column>
      <pl-table-column prop="VF" label="VF" width="120"></pl-table-column>
      <pl-table-column prop="IR" label="IR" width="120"></pl-table-column>
      <pl-table-column prop="I" label="I" width="120"></pl-table-column>
      <pl-table-column prop="Ee" label="Ee" width="120"></pl-table-column>
      <pl-table-column prop="IP" label="IP" width="120"></pl-table-column>
      <pl-table-column prop="WLP1" label="WLP1" width="120"></pl-table-column>
      <pl-table-column prop="WLP_STD" label="WLP STD" width="120"></pl-table-column>
      <pl-table-column prop="WLD" label="WLD" width="120"></pl-table-column>
      <pl-table-column prop="WD_STD" label="WD STD" width="120"></pl-table-column>
      <pl-table-column prop="HW" label="HW" width="120"></pl-table-column>
      <pl-table-column prop="色纯度" label="色纯度" width="120"></pl-table-column>
      <pl-table-column prop="翘曲" label="翘曲" width="120"></pl-table-column>
      <pl-table-column prop="厚度" label="厚度" width="120"></pl-table-column>
      <pl-table-column prop="厚度std" label="厚度std" width="120"></pl-table-column>
      <pl-table-column prop="Bulk_Concentration" label="Bulk Concentration" width="120"></pl-table-column>
      <pl-table-column prop="Sheet_Concentration" label="Sheet Concentration" width="120"></pl-table-column>
      <pl-table-column prop="Mobility" label="Mobility" width="120"></pl-table-column>
      <pl-table-column prop="Resistivity" label="Resistivity" width="120"></pl-table-column>
      <pl-table-column prop="XRD102" label="XRD102" width="120"></pl-table-column>
      <pl-table-column prop="XRD002" label="XRD002" width="120"></pl-table-column>
      <pl-table-column prop="备用列1" label="备用列1" width="120"></pl-table-column>
      <pl-table-column prop="备用列2" label="备用列2" width="120"></pl-table-column>
    </pl-table>
  </div>
</template>

<script>
import {defaultOptions} from '../configs/squareConfig'
import moment from "moment";

export default {
  name: "ExTable",
  props: ['tableData', 'myName'],
  data() {
    return {
      colScreen: {},
      defaultOptions: defaultOptions,
    }
  },
  methods: {
    EmitColScreen() {
      if (this.myName === 'rwTable') {
        this.$message({
          type: "error",
          message: '对不起，覆写功能需要同时处理新、旧两个数据，暂不支持筛选、排序'
        })
        this.colScreen = {}
        return
      }
      this.colScreen.myName = this.myName
      this.$emit('change-screen', this.colScreen)
    },
  },
  computed: {
    GetDate() {
      return date => date ? moment(date).format('YYYY/MM/DD') : ''
    },
  },
}
</script>

<style scoped>

</style>
